export const getAllCodesStart = () => ({
    type: "GET_ALL_CODES_START",
});

export const getAllCodesSuccess = (codes) => ({
    type: "GET_ALL_CODES_SUCCESS",
    payload: codes,
});

export const getAllCodesFailure = () => ({
    type: "GET_ALL_CODES_FAILURE",
});

export const createCodesStart = () => ({
    type: "CREATE_CODES_START",
});

export const createCodesSuccess = (codes) => ({
    type: "CREATE_CODES_SUCCESS",
    payload: codes,
});

export const createCodesFailure = () => ({
    type: "CREATE_CODES_FAILURE",
});

export const deleteCodesStart = () => ({
    type: "DELETE_CODES_START",
});

export const deleteCodesSuccess = (id) => ({
    type: "DELETE_CODES_SUCCESS",
    payload: id,
});

export const deleteCodesFailure = () => ({
    type: "DELETE_CODES_FAILURE",
});