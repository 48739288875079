import "./widgetSm.css";
import { Visibility } from "@material-ui/icons";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import web3 from "web3";


export default function WidgetSm() {
  const [userData, setUserData] = useState();

  useEffect(() => {
    loadUserData()
  }, [])

  const loadUserData = async () => {
    try {
      const res = await axios.get("https://loayltyapi.herokuapp.com/api/users/", {
        headers: {
          token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken
        },
      });
      setUserData(res.data.filter((user) => user.owner == JSON.parse(localStorage.getItem("user"))._id))
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="widgetSm">
      <span className="widgetSmTitle">Fleet EV Drivers</span>
      <ul className="widgetSmList">
        {userData && userData.map((user) => {
          return (
            <li key={user._id} className="widgetSmListItem">
              <div className="widgetSmUser">
                <span className="widgetSmUsername">{user.username}</span>
                <span className="widgetSmUserTitle">{user.isAdmin ? "Admin" : null}</span>
              </div>
              
              <Link className="widgetSmButton" to={{ pathname: "/user/" + user._id, user: user }}>
              <Visibility className="widgetSmIcon" />Detail
            </Link>
            </li>
          )
        })}
      </ul>
    </div>
  );
}
