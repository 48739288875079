export const getTokensStart = () => ({
    type: "GET_TOKENS_START",
});

export const getTokensSuccess = (tokens) => ({
    type: "GET_TOKENS_SUCCESS",
    payload: tokens,
});

export const getTokensFailure = () => ({
    type: "GET_TOKENS_FAILURE",
});

export const getBalanceStart = () => ({
    type: "GET_BALANCE_START",
});

export const getBalanceSuccess = (balance) => ({
    type: "GET_BALANCE_SUCCESS",
    payload: balance,
});

export const getBalanceFailure = () => ({
    type: "GET_BALANCE_FAILURE",
});

export const getTokensDetailStart = () => ({
    type: "GET_TOKENS_DETAIL_START",
});

export const getTokensDetailSuccess = (tokenDetail) => ({
    type: "GET_TOKENS_DETAIL_SUCCESS",
    payload: tokenDetail,
});

export const getTokensDetailFailure = () => ({
    type: "GET_TOKENS_DETAIL_FAILURE",
});

export const createTokensStart = () => ({
    type: "CREATE_TOKEN_START",
});

export const createTokensSuccess = (tokens) => ({
    type: "CREATE_TOKEN_SUCCESS",
    payload: tokens,
});

export const createTokenFailure = () => ({
    type: "CREATE_TOKEN_FAILURE",
});

export const updateTokensStart = () => ({
    type: "UPDATE_TOKEN_START",
});

export const updateTokensSuccess = (tokens) => ({
    type: "UPDATE_TOKEN_SUCCESS",
    payload: tokens,
});

export const updateTokenFailure = () => ({
    type: "UPDATE_TOKEN_FAILURE",
});

export const mintTokensStart = () => ({
    type: "MINT_TOKENS_START",
});

export const mintTokensSuccess = (tokens) => ({
    type: "MINT_TOKENS_SUCCESS",
    payload: tokens,
});

export const mintTokenFailure = () => ({
    type: "MINT_TOKENS_FAILURE",
});

export const burnTokensStart = () => ({
    type: "BURN_TOKENS_START",
});

export const burnTokensSuccess = (tokens) => ({
    type: "BURN_TOKENS_SUCCESS",
    payload: tokens,
});

export const burnTokensFailure = () => ({
    type: "BURN_TOKENS_FAILURE",
});