export const getCampaignsStart = () => ({
    type: "GET_CAMPAIGNS_START",
});

export const getCampaignsSuccess = (campaigns) => ({
    type: "GET_CAMPAIGNS_SUCCESS",
    payload: campaigns,
});

export const getCampaignsFailure = () => ({
    type: "GET_CAMPAIGNS_FAILURE",
});

export const createCampaignsStart = () => ({
    type: "CREATE_CAMPAIGN_START",
});

export const createCampaignsSuccess = (campaign) => ({
    type: "CREATE_CAMPAIGN_SUCCESS",
    payload: campaign,
});

export const createCampaignFailure = () => ({
    type: "CREATE_CAMPAIGN_FAILURE",
});

export const updateCampaignstart = () => ({
    type: "UPDATE_CAMPAIGN_START",
});

export const updateCampaignsuccess = (campaign) => ({
    type: "UPDATE_CAMPAIGN_SUCCESS",
    payload: campaign,
});

export const updateCampaignFailure = () => ({
    type: "UPDATE_CAMPAIGN_FAILURE",
});

export const deleteCampaignStart = () => ({
    type: "DELETE_CAMPAIGN_START",
});

export const deleteCampaignSuccess = (id) => ({
    type: "DELETE_CAMPAIGN_SUCCESS",
    payload: id,
});

export const deleteCampaignFailure = () => ({
    type: "DELETE_CAMPAIGN_FAILURE",
});