const SingleUserReducer = (state, action) => {
    switch (action.type) {
        case "GET_USER_START":
            return {
                userData: {},
                isFetching: true,
                error: false,
            };
        case "GET_USER_SUCCESS":
            return {
                userData: action.payload,
                isFetching: false,
                error: false,
            };
        case "GET_USER_FAILURE":
            return {
                userData: {},
                isFetching: false,
                error: true,
            };
        default:
            return { ...state };
    }
};

export default SingleUserReducer;
