import "./widgetLg.css";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import web3 from "web3";
import Alert from '@material-ui/lab/Alert';

export default function WidgetLg() {
  const [txData, setTxData] = useState([]);

  useEffect(() => {
    loadtxData()
  }, [])

  const loadtxData = async () => {
    try {
      const res = await axios.get("https://loayltyapi.herokuapp.com/api/token/tx/all", {
        headers: {
          token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken
        },
      });
      let filteredTxs = res.data.filter((user) => user.owner == JSON.parse(localStorage.getItem("user"))._id)
      setTxData(filteredTxs.reverse().slice(0, 10))
      
    } catch (err) {
      console.log(err);
    }
  }

  const converFromWei = (amount) => {
    return web3.utils.fromWei(String(amount))
  }

  const Button = ({ type }) => {
    return <button className={"widgetLgButton " + type}>{type}</button>;
  };

  const formatDate = (date) => {
    let formatedDate = new Date(String(date)).toUTCString()
    return formatedDate;
  }

  console.log('txData ', txData)

  return (
    <div className="widgetLg">
      <h3 className="widgetLgTitle">Latest transactions</h3>
      {txData.length == 0 ?
        <div className="no-data-alert"><Alert severity="info">No data to display yet</Alert></div>
        :
        <table className="widgetLgTable">
          <tbody>
            <tr className="widgetLgTr">
              <th className="widgetLgTh">Date</th>
              <th className="widgetLgTh">Amount</th>
              <th className="widgetLgTh">Action</th>
            </tr>
            {txData && txData.map((tx) => {
              return (
                <tr key={tx._id} className="widgetLgTr">
                  <td className="widgetLgDate">{formatDate(tx.createdAt)}</td>
                  {/* <td className="widgetLgDate">{tx.createdAt}</td> */}
                  <td className="widgetLgAmount">{tx.amount && converFromWei(tx.amount)}</td>
                  <td className="widgetLgStatus">
                    <Button type="Mint" />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      }
    </div>
  );
}
