import Chart from "../../components/chart/Chart";
import FeaturedInfo from "../../components/featuredInfo/FeaturedInfo";
import "./home.css";
import { userData } from "../../dummyData";
import WidgetSm from "../../components/widgetSm/WidgetSm";
import WidgetLg from "../../components/widgetLg/WidgetLg";
import { CircularProgress } from "@material-ui/core";
import { Modal, Box, Button, Typography, styled } from "@material-ui/core"
import { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import Alert from '@material-ui/lab/Alert';
import {
  mintTokens
} from "../../context/tokenContext/apiCalls";
import { CodesContext } from "../../context/codesContext/CodesContext";
import { getAllCodes, createCode, deleteCode } from "../../context/codesContext/apiCalls";

export default function Home() {
  const MONTHS = useMemo(
    () => [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    []
  );

  // modal css
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const [userStats, setUserStats] = useState([]);
  const [statList, setstatList] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [tokenId, setTokenID] = useState();
  const { codes, isFetching, error, dispatch } = useContext(CodesContext);
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);
  const [openMint, setMintModalOpen] = useState(false);
  const [tokenEurAmount, setTokenEurAmount] = useState();
  // const [loadingMint, setLoadingMint] = useState(false);

  useEffect(() => {
    getStats();
  }, [MONTHS]);

  const getStats = async () => {
    setLoading(false)
    try {
      const res = await axios.get("https://loayltyapi.herokuapp.com/api/token/tx/stats", {
        headers: {
          token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken
        },
      });
      const statsList = res.data.sort(function (a, b) {
        return a._id - b._id;
      });
      setstatList(statsList)
      statsList.map((item) =>
        setUserStats((prev) => [
          ...prev,
          { name: MONTHS[item._id - 1], "Mint TX": item.total },
        ])
      );
      
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setTokenEurAmount({ ...tokenEurAmount, [e.target.name]: value })
  };

  const handleMintModalOpen = () => {
    setMintModalOpen(true)
    setSuccessfulSubmit(false)
  }

  const handleMintModalClose = () => {
    setMintModalOpen(false);
    window.location.reload()
  }

  const mintToUserAccount = async (e) => {
    e.preventDefault();

    // setLoadingMint(true)

    console.log('tokenEurAmount.userEmailMint ', tokenEurAmount.userEmailMint)

    // return false;
    // loyalty@agevolt.com
    try {
      const res = await axios.post("https://loayltyapi.herokuapp.com/api/auth/pre-register", {
        "email": tokenEurAmount.userEmailMint
      }, {
        headers: {
          token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken
        },
      });

      console.log('res.data ', res.data.userPublicKey)

      mintTokens(JSON.parse(localStorage.getItem("user")).tokenId, {
        "privateKey": JSON.parse(localStorage.getItem("user")).walletPrivateKey.substring(2),
        "toAddress": res.data.userPublicKey,
        "tokenAmount": tokenEurAmount.eurAmountMint,
        "owner": JSON.parse(localStorage.getItem("user"))._id
      }, dispatch)

      if (!error) {
        // setLoadingMint(false)
        setSuccessfulSubmit(true)
      } else {
        console.error('UPDATE API ERROR ', error)
        setSuccessfulSubmit(false)
      }
    } catch (err) {
      console.log(err);
    }
  }


  return (
    !loading &&
    <div className="home">
      <div className="dashboard-title">
        <h1>Dashboard</h1>
        <button onClick={handleMintModalOpen} className="dashboard-title-btn updateCampaignButton">Mint New Tokens</button>
      </div>
      {statList.length == 0 ? <div className="no-data-alert"><Alert severity="info">No data to display yet</Alert></div> :
        <Chart data={userStats} title="Token Tx Analytics - Minted vs. Redeemed" grid dataKey="Mint TX" />}

      <FeaturedInfo />
      <div className="homeWidgets">
        <WidgetSm />
        <WidgetLg />
      </div>


      <Modal
        open={openMint}
        onClose={handleMintModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box css={style}>
          <form className="userUpdateForm">
            <div className="userUpdateLeft">
              <div className="userUpdateItem">
                <h2>Mint</h2>
                <br />
                <label>Enter amount of revenue from customer - amount will be calculated by your coeficient specified in loyalty token settings.</label>
                <br />
                <input
                  type="number"
                  placeholder="Amount of EUR"
                  name="eurAmountMint"
                  onChange={handleChange}
                />
                <br />
                <label>Enter user email address</label>
                <br />
                <input
                  type="email"
                  placeholder="Email"
                  name="userEmailMint"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="userUpdateRight">
              {!isFetching ?
                <button className="updateCampaignButton" onClick={mintToUserAccount}>Mint</button>
                :
                <button className="updateCampaignButton updateCampaignButtonSpinner" disabled>
                  <CircularProgress style={{ "width": "20px", "height": "20px" }} />
                </button>
              }
            </div>
          </form>
          <br />
          {error && !successfulSubmit && <Alert severity="error">Unfortunately error occured</Alert>}
          {successfulSubmit && <Alert severity="success">Tokens are minted</Alert>}
        </Box>
      </Modal>
    </div>
  );
}
