const SingleCampaignReducer = (state, action) => {
    switch (action.type) {
        case "GET_CAMPAIGN_START":
            return {
                campaignData: {},
                isFetching: true,
                error: false,
            };
        case "GET_CAMPAIGN_SUCCESS":
            return {
                campaignData: action.payload,
                isFetching: false,
                error: false,
            };
        case "GET_CAMPAIGN_FAILURE":
            return {
                campaignData: {},
                isFetching: false,
                error: true,
            };
        case "UPDATE_CAMPAIGN_START":
            return {
                campaignData: {},
                isFetching: true,
                error: false,
            };
        case "UPDATE_CAMPAIGN_SUCCESS":
            return {
                campaignData: action.payload,
                isFetching: false,
                error: false,
            };
        case "UPDATE_CAMPAIGN_FAILURE":
            return {
                campaignData: {},
                isFetching: false,
                error: true,
            };
        default:
            return { ...state };
    }
};

export default SingleCampaignReducer;
