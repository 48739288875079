import "./userList.css";
import { DataGrid } from "@material-ui/data-grid";
import { CircularProgress } from "@material-ui/core";
import { DeleteOutline } from "@material-ui/icons";
import Alert from '@material-ui/lab/Alert';
import { Link } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "../../context/userContext/UserContext";
import { deleteUser, getUsers } from "../../context/userContext/apiCalls";

export default function UserList() {
  const { users, isFetching, dispatch } = useContext(UserContext);

  useEffect(() => {
    getUsers(dispatch)
  }, [dispatch])

  const handleDelete = (id) => {
    deleteUser(id, dispatch);
  };

  const columns = [
    // { field: "_id", headerName: "ID", width: 200 },
    {
      field: "username",
      headerName: "User",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="userListUser">
            {/* <img className="userListImg" src={params.row.avatar} alt="" /> */}
            {params.row.username}
          </div>
        );
      },
    },
    // {
    //   field: "isAdmin",
    //   headerName: "Admin",
    //   width: 160,
    // },
    { field: "email", headerName: "Email", width: 300 },
    {
      field: "createdAt",
      headerName: "Registration date",
      width: 300,
    },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <Link to={{ pathname: "/user/" + params.row._id, user: params.row }}>
              <button className="userListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="userListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="userList">
      <div className="userTitleContainer">
        <h1 className="userTitle">{"EV Drivers Fleet"}</h1>
        <Link to="/new-user-access">
          <button className="updateCampaignButton">New Driver</button>
        </Link>
      </div>
      <div>
        {!isFetching
          ?
          <div>
            <DataGrid
              autoHeight
              rows={users}
              disableSelectionOnClick
              columns={columns}
              pageSize={8}
              checkboxSelection
              getRowId={(r) => r._id}
            />
            <br/>
            {!users.length > 0 ? <Alert severity="info">No additional user access created yet.</Alert> : null}
          </div>
          :
          <div className="loading_spinner">
            <CircularProgress />
          </div>
        }
      </div>
    </div>
  );
}
