import axios from "axios";
import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  VerifiedUser,
  Publish,
  ExpandMore,
  AccountBalanceWallet
} from "@material-ui/icons";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@material-ui/core"
import Alert from '@material-ui/lab/Alert';
import { Link, useLocation, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { SingleUserContext } from "../../context/singleUserContext/SingleUserContext";
import { getUserById } from "../../context/singleUserContext/apiCalls";
import { TokenContext } from "../../context/tokenContext/TokenContext";
import {
  getTokenDetails,
  getBalance,
  mintTokens
} from "../../context/tokenContext/apiCalls";
import { CircularProgress } from "@material-ui/core";
import "./user.css";
import web3 from "web3";
import dotenv from "dotenv";
dotenv.config();

export default function User() {

  const params = useParams();
  const { isFetching, error, dispatch } = useContext(SingleUserContext);
  const [tokenId, setTokenID] = useState(JSON.parse(localStorage.getItem("user")).tokenId);
  const { tokens, tokenDetail, balance } = useContext(TokenContext);
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);
  const [tokenMint, setTokenMint] = useState();
  const [userData, setUserData] = useState();
  const [tokenData, setTokenData] = useState();
  const [tokenBalance, setTokenBalance] = useState(0);

  useEffect(() => {
    loadUserData(params.userId)
  }, [])

  const loadUserData = async (userId) => {
    try {
      const res = await axios.get("https://loayltyapi.herokuapp.com/api/users/find/" + userId, {
        headers: {
          token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken
        },
      });
      setUserData(res.data)
      loadTokenData(tokenId, res.data)
    } catch (err) {
      console.log(err);
    }
  }

  const loadTokenData = async (tokenId, userLoadedData) => {
      try {
        const res = await axios.get("https://loayltyapi.herokuapp.com/api/token/detail/" + tokenId, {
          headers: {
            token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken
          },
        });
        setTokenData(res.data)
        // console.log('res.data ', res.data)
        loadTokenBalance(res.data.contractAddress, userLoadedData.walletAddress)
      } catch (err) {
        console.log(err);
      }
  }

  const loadTokenBalance = async (contractAddress, userWalletAddress) => {
    // console.log('contractAddress, userWalletAddress ', contractAddress, userWalletAddress) 
    try {
      const balance = await axios.get("https://loayltyapi.herokuapp.com/api/token/balance/" + contractAddress + "/" + userWalletAddress, {
        headers: {
          token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      });
      setTokenBalance(balance.data)
    } catch (err) {
      console.log(err);
    }
  }

  const handleMint = (e) => {
    e.preventDefault();

    mintTokens(tokenId, {
      "privateKey": JSON.parse(localStorage.getItem("user")).walletPrivateKey.substring(2),
      "toAddress": userData.walletAddress,
      "tokenAmount": tokenMint.mintAmount,
      "owner": JSON.parse(localStorage.getItem("user"))._id
    }, dispatch)

    if (!error) {
      console.log('userData ', userData) 
      loadTokenBalance(tokenData.contractAddress, userData.walletAddress)
      setSuccessfulSubmit(true)
    } else {
      console.error('UPDATE API ERROR ', error)
      setSuccessfulSubmit(false)
    }
  }

  const handleChange = (e) => {
    const value = e.target.value;
    setTokenMint({ ...tokenMint, [e.target.name]: value })
  }

  const converFromWei = (amount) => {
    return web3.utils.fromWei(String(amount))
  }

  const returnLoader = () => {
    return <CircularProgress style={{ "margin-left": "10px", "width": "15px", "height": "15px" }} />
  }

  return (
    <div className="userDetailContainer">
      <div className="user">
        <div className="userTitleContainer">
          <h1 className="userTitle">Edit Driver</h1>
        </div>
        {!isFetching ?
          <div className="userContainer">
            <div className="userShow">
              <div className="userShowBottom">
                <span className="userShowTitle">Account Details</span>
                <div className="userShowInfo">
                  <PermIdentity className="userShowIcon" />
                  <span className="userShowInfoTitle"><b>User name:</b> {userData && userData.username ? userData.createdAt : returnLoader()}</span>
                </div>
                <div className="userShowInfo">
                  <CalendarToday className="userShowIcon" />
                  <span className="userShowInfoTitle"><b>Registration date:</b> {userData && userData.createdAt ? userData.createdAt : returnLoader()}</span>
                </div>
                <div className="userShowInfo">
                  <VerifiedUser className="userShowIcon" />
                  <span className="userShowInfoTitle"><b>Admin permissions:</b> {userData && userData.isAdmin ? "Yes" : "No"}</span>
                </div>
                <div className="userShowInfo">
                  <MailOutline className="userShowIcon" />
                  <span className="userShowInfoTitle"><b>Email:</b> {userData && userData.email ? userData.email : returnLoader()}</span>
                </div>
                <div className="userShowInfo">
                  <AccountBalanceWallet className="userShowIcon" />
                  <span className="userShowInfoTitle"><b>Token balance:</b> {tokenBalance ? converFromWei(tokenBalance.balance) + ' ' + tokenData.symbol : returnLoader()}</span>
                </div>
              </div>
            </div>
            <div className="userUpdate tokenAccordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <b>{'Set admin permissions'}</b>
                </AccordionSummary>
                <AccordionDetails>
                  <form className="userUpdateForm">
                    <div className="userUpdateLeft">
                      <div className="userUpdateItem">
                        <label>Set admin permissions</label>
                        <select className="newUserSelect" name="active" id="active" disabled="true">
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="userUpdateRight">
                      <button disabled="true" className="updateCampaignButton">Update</button>
                    </div>
                  </form>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <b>{'Mint & assign tokens'}</b>
                </AccordionSummary>
                <AccordionDetails>
                  <form className="userUpdateForm">
                    <div className="userUpdateLeft">
                      <div className="userUpdateItem">
                        <label>Mint and assign new tokens to user</label>
                        <input
                          type="text"
                          placeholder="Amount"
                          name="mintAmount"
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="userUpdateRight">
                      {!isFetching ?
                        <button className="updateCampaignButton" onClick={handleMint}>Mint tokens</button>
                        :
                        <button className="updateCampaignButton updateCampaignButtonSpinner" disabled>
                          <CircularProgress style={{ "width": "20px", "height": "20px" }} />
                        </button>
                      }
                    </div>
                  </form>
                  <br />
                  {error && !successfulSubmit && <Alert severity="error">Unfortunately error occured</Alert>}
                  {successfulSubmit && <Alert severity="success">Token is minted</Alert>}
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          :
          <div className="loading_spinner">
            <CircularProgress />
          </div>
        }
      </div>
      <div className="footerUserDetail">
        <Link to="/users">
          <button className="updateCampaignButton">Back</button>
        </Link>
      </div>
    </div>
  );
}
