import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthContextProvider } from "./context/authContext/AuthContext";
import { UserContextProvider } from "./context/userContext/UserContext";
import { SingleUserContextProvider } from "./context/singleUserContext/SingleUserContext";
import { CampaignContextProvider } from "./context/campaignContext/CampaignContext";
import { SingleCampaignContextProvider } from "./context/singleCampaignContext/SingleCampaignContext";
import { TokenContextProvider } from "./context/tokenContext/TokenContext";
import { CodesContextProvider } from "./context/codesContext/CodesContext";

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <UserContextProvider>
        <SingleUserContextProvider>
          <CampaignContextProvider>
            <SingleCampaignContextProvider>
              <TokenContextProvider>
                <CodesContextProvider>
                  <App />
                </CodesContextProvider>
              </TokenContextProvider>
            </SingleCampaignContextProvider>
          </CampaignContextProvider>
        </SingleUserContextProvider>
      </UserContextProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
