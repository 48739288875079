import "./newCampaign.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { CampaignContext } from "../../context/campaignContext/CampaignContext";
import { createCampaign } from "../../context/campaignContext/apiCalls";
import { CircularProgress } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { Switch } from "@material-ui/core";

export default function NewCampaign() {

  const params = useParams();
  const { campaignData, isFetching, error, dispatch } = useContext(CampaignContext);
  const [successfulSubmit, setSuccessfulSubmit] = useState(null);
  const [submitError, setSubmitError] = useState(false);
  const [campaign, setCampaign] = useState();

  const handleChange = (e) => {
    const value = e.target.value;
    if (e.target.type == "checkbox") {
      setCampaign({ ...campaign, [e.target.name]: e.target.checked })
    }
    setCampaign({ ...campaign, [e.target.name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      createCampaign({
        "tokensToAlloc": campaign.campaignTokensToAlloc,
        "expirationDate": campaign.campaignExpirationDate,
        "title": campaign.campaignTitle,
        "description": campaign.campaignDescription,
        "totalBudgetQty": campaign.campaignTotalBudgetQty,
        "unlimitedBudget": campaign.campaignUnlimitedBudget ? "false" : "true",
        "maxPerCustomerQty": campaign.campaignMaxPerCustomerQty,
        "maxPerCustomerUnlimited": campaign.campaignMaxPerCustomerUnlimited ? "false" : "true"
      }, dispatch)
    } catch (err) {
      setSuccessfulSubmit(false)
      setSubmitError(true)
      // console.error('SUBMIT API ERROR ', error)
    }
    if (!error) {
      setSuccessfulSubmit(true)
    }
  }

  return (
    <div className="newProduct">
      <h1 className="addProductTitle">New Campaign</h1>
      <div className="productBottom">
        <form className="productForm">
          <div className="productFormLeft">
            <div className="productFormLeftItem">
              <label>Campaign title</label><br />
              <input
                type="text"
                placeholder="Title"
                name="campaignTitle"
                onChange={handleChange}
              />
            </div>
            <br />
            <div className="productFormLeftItem">
              <label>Campaign description</label><br />
              <textarea
                type="text"
                placeholder="Description"
                name="campaignDescription"
                onChange={handleChange}
              />
            </div>
            <br />
            <div className="productFormLeftItem">
              <label>Tokens to allocate Qty:</label><br />
              <input
                type="number"
                placeholder="Tokens to allocate Qty"
                name="campaignTokensToAlloc"
                onChange={handleChange}
              />
            </div>
            <br />
            <div className="productFormLeftItem">
              <label>Expiration date:</label><br />
              <input
                type="text"
                placeholder="Expiration date"
                name="campaignExpirationDate"
                onChange={handleChange}
              />
            </div>
            <br />
            <div className="productFormLeftItem">
              <label>Total budget Qty:</label><br />
              <input
                type="number"
                placeholder="Total budget Qty"
                name="campaignTotalBudgetQty"
                onChange={handleChange}
              />
            </div>
            <br />
            <div className="productFormLeftItem">
              <label>Max per customer Qty:</label><br />
              <input
                type="number"
                placeholder="Total budget Qty"
                name="campaignMaxPerCustomerQty"
                onChange={handleChange}
              />
            </div>
            <br />
            <div className="productFormLeftItem">
              <label>Total budget is unlimited:</label><br />
              <Switch
                label="Total budget is unlimited:"
                name="campaignUnlimitedBudget"
                checked={true}
                onChange={handleChange}
              />
            </div>
            <br />
            <div className="productFormLeftItem">
              <label>Max qty per customer is unlimited:</label><br />
              <Switch
                label="Max qty per customer is unlimited:"
                name="campaignMaxPerCustomerUnlimited"
                checked={true}
                onChange={handleChange}
              />
            </div>
            <br />
            {!isFetching ?
              <button className="updateCampaignButton" onClick={handleSubmit}>Create campaign</button>
              :
              <button className="updateCampaignButton updateCampaignButtonSpinner" disabled>
                <CircularProgress style={{ "width": "20px", "height": "20px" }} />
              </button>
            }
          </div>
        </form>
        {submitError && <Alert severity="error">Unfortunately error occured. We are working on it. Please try again later or contact support.</Alert>}
        {!submitError && successfulSubmit && <Alert severity="success">Campaign is successfully created</Alert>}
      </div>
      <div className="campaignDetailBack">
        <Link to="/campaigns">
          <button className="campaignDetailBackButton">{"<<< Back to list"}</button>
        </Link>
      </div>
    </div>
  );
}
