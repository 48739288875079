import axios from "axios";

import {
    createUserFailure,
    createUsersStart,
    createUsersSuccess,
    deleteUserFailure,
    deleteUserStart,
    deleteUserSuccess,
    getUsersFailure,
    getUsersStart,
    getUsersSuccess
  } from "./UserActions";

  export const getUsers = async (dispatch) => {
    dispatch(getUsersStart());
    try {
      const res = await axios.get("https://loayltyapi.herokuapp.com/api/users", {
        headers: {
          token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      });
      // dispatch(getUsersSuccess(res.data.filter((user) => user._id !== JSON.parse(localStorage.getItem("user"))._id)));
      // dispatch(getUsersSuccess(res.data));
      dispatch(getUsersSuccess(res.data.filter((user) => user.owner == JSON.parse(localStorage.getItem("user"))._id)));
    } catch (err) {
      console.error("API ERROR: ", err)
      dispatch(getUsersFailure());
    }
  };

  //create
export const createUser = async (user, dispatch) => {
  dispatch(createUsersStart());
  try {
    const res = await axios.post("https://loayltyapi.herokuapp.com/api/auth/register", user, {
      headers: {
        // token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createUsersSuccess(res.data));
  } catch (err) {
    console.error("API ERROR: ", err)
    dispatch(createUserFailure());
  }
};

  export const deleteUser = async (id, dispatch) => {
    dispatch(deleteUserStart());
    try {
      await axios.delete("https://loayltyapi.herokuapp.com/api/users/"+id, {
        headers: {
          token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
        },
      });
      dispatch(deleteUserSuccess(id));
    } catch (err) {
      console.error("API ERROR: ", err)
      dispatch(deleteUserFailure());
    }
  };