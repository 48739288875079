import TokenReducer from "./TokenReducer";
import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  tokens: [],
  tokenDetail: [],
  balance: 0,
  isFetching: false,
  error: false,
};

export const TokenContext = createContext(INITIAL_STATE);

export const TokenContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(TokenReducer, INITIAL_STATE);

  return (
    <TokenContext.Provider
      value={{
        tokens: state.tokens,
        tokenDetail: state.tokenDetail,
        balance: state.balance,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};
