import "./codes.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline, Print } from "@material-ui/icons";
import { Modal, Box, Button, Typography, styled } from "@material-ui/core"
import Alert from '@material-ui/lab/Alert';
import { CircularProgress } from "@material-ui/core";
import { productRows } from "../../dummyData";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useContext, useEffect } from "react";
import { CodesContext } from "../../context/codesContext/CodesContext";
import { getAllCodes, createCode, deleteCode } from "../../context/codesContext/apiCalls";
import {
  mintTokens
} from "../../context/tokenContext/apiCalls";
import ReactPDF, { Page, Text, View, Document, StyleSheet, PDFViewer, Image, PDFDownloadLink } from '@react-pdf/renderer';
import QRCode from 'qrcode.react';
import { jsPDF } from "jspdf";
import axios from "axios";


export default function Codes() {
  const [data, setData] = useState(productRows);
  const { codes, isFetching, error, dispatch } = useContext(CodesContext);
  const [tokenId, setTokenID] = useState(JSON.parse(localStorage.getItem("user")).tokenId);
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [openMint, setMintModalOpen] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [pdfData, setPdfData] = useState({});
  const [tokenEurAmount, setTokenEurAmount] = useState();

  // NOTES:
  // https://maustrauk.medium.com/creating-qr-code-and-pdf-document-by-react-js-cd5868af0b8e

  // Create pdf styles
  const pdfStyles = StyleSheet.create({
    page: {
      backgroundColor: 'white',
      display: 'block'
    },
    view: {
      // display: 'flex',
      // flexDirection: 'row',
      paddingTop: '20px',
      justifyContent: 'center',
      width: '100%'
    },
    // QRImage: {
    //   width: '100%',
    //   height: '100%'
    // },
    // section: {
    //   margin: 10,
    //   padding: 10,
    //   flexGrow: 1
    // },
    text: {
      paddingTop: '10px',
      color: '#0081C6',
      size: '10px',
      textAlign: 'center'
    },
    qrImage: {
      marginLeft: '45px',
      width: '80px',
      height: '80px'
    }
  });

  // modal css
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    getAllCodes(dispatch)
  }, [dispatch])

  const handleOpen = () => {
    setOpen(true)
    setSuccessfulSubmit(false)
  }

  const handleClose = () => setOpen(false);

  const handlePdfOpen = (data) => {

    var canvas = document.getElementById('qr_' + data._id);
    var dataURL = canvas.toDataURL();

    setPdfData({
      created: data.createdAt,
      amount: data.amount,
      dataUrl: dataURL
    })

    setPdfOpen(true);
  }

  const handlePdfClose = () => setPdfOpen(false);

  const handleChange = (e) => {
    const value = e.target.value;
    setTokenEurAmount({ ...tokenEurAmount, [e.target.name]: value })
  };

  const handleCreate = (e) => {
    e.preventDefault();

    if (tokenEurAmount && tokenEurAmount.eurAmount != 0) {
      console.log('tokenEurAmount ', tokenEurAmount.eurAmount)
      createCode(tokenId, {
        "orderValue": tokenEurAmount.eurAmount
      }, dispatch)
    }

    if (!error) {
      getAllCodes(dispatch)
      setSuccessfulSubmit(true)
    } else {
      console.error('UPDATE API ERROR ', error)
      setSuccessfulSubmit(false)
    }
  };

  const handlePrint = () => {

  };

  const handleDelete = (id) => {
    deleteCode(id, dispatch)
  };

  const handleMintModalOpen = () => {
    setMintModalOpen(true)
    setSuccessfulSubmit(false)
  }

  const handleMintModalClose = () => setMintModalOpen(false);

  const mintToUserAccount = async (e) => {
    e.preventDefault();

    console.log('tokenEurAmount.userEmailMint ', tokenEurAmount.userEmailMint)

    // return false;
    // loyalty@agevolt.com
    try {
      const res = await axios.post("https://loayltyapi.herokuapp.com/api/auth/pre-register", {
        "email": tokenEurAmount.userEmailMint
      }, {
        headers: {
          token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken
        },
      });

      console.log('res.data ', res.data.userPublicKey)

      mintTokens(tokenId, {
        "privateKey": JSON.parse(localStorage.getItem("user")).walletPrivateKey.substring(2),
        "toAddress": res.data.userPublicKey,
        "tokenAmount": tokenEurAmount.eurAmountMint
      }, dispatch)
  
      if (!error) {
        setSuccessfulSubmit(true)
      } else {
        console.error('UPDATE API ERROR ', error)
        setSuccessfulSubmit(false)
      }
    } catch (err) {
      console.log(err);
    }
  }

  const columns = [
    {
      field: "createdAt",
      headerName: "Create date",
      width: 300
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 220,
    },
    {
      field: "printed",
      headerName: "Print",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="codeListItem">
            <div className="hidden_qr">
              <QRCode
                id={`qr_${params.row._id}`}
                size={20}
                value={params.row.amount}
                renderAs="canvas"
              />
            </div>
            {params.row.printed ? <button disabled>Printed</button> : <Print className="print-btn" onClick={() => handlePdfOpen(params.row)}>{'Print'}</Print>}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <DeleteOutline
              className="productListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    }
  ];

  return (
    <div className="productList">
      <div className="userTitleContainer">
        <h1 className="userTitle">Mint tokens</h1>
        <button onClick={handleOpen} className="updateCampaignButton">New code</button>
      </div>
      <small>Mint new tokens directly to user account using just his email address or create and print QR codes for maximum user comfortness.</small>
      
      <div>
        {!isFetching
          ?
          <div>
            <Modal
              open={openMint}
              onClose={handleMintModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box css={style}>
                <form className="userUpdateForm">
                  <div className="userUpdateLeft">
                    <div className="userUpdateItem">
                      <h2>Mint</h2>
                      <br />
                      <label>Enter amount of revenue from customer - amount will be calculated by your coeficient specified in loyalty token settings.</label>
                      <br />
                      <input
                        type="number"
                        placeholder="Amount of EUR"
                        name="eurAmountMint"
                        onChange={handleChange}
                      />
                      <br />
                      <label>Enter user email address</label>
                      <br />
                      <input
                        type="email"
                        placeholder="Email"
                        name="userEmailMint"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="userUpdateRight">
                    {!isFetching ?
                      <button className="updateCampaignButton" onClick={mintToUserAccount}>Mint</button>
                      :
                      <button className="updateCampaignButton updateCampaignButtonSpinner" disabled>
                        <CircularProgress style={{ "width": "20px", "height": "20px" }} />
                      </button>
                    }
                  </div>
                </form>
                <br />
                {error && !successfulSubmit && <Alert severity="error">Unfortunately error occured</Alert>}
                {successfulSubmit && <Alert severity="success">Tokens are minted</Alert>}
              </Box>
            </Modal>
            <Modal
              open={pdfOpen}
              onClose={handlePdfClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box css={style}>
                <div className="pdf-viewer">
                  {/* {pdfData.createdAt}
                  <QRCode
                    id={`qr`}
                    size={120}
                    value={pdfData.key}
                    renderAs="canvas"
                  /> */}
                </div>
                <br />
                {/* <button className="updateCampaignButton" onClick={handlePrint}>Print</button> */}
                <PDFDownloadLink
                  document={
                    <Document>
                      <Page size="B8" style={pdfStyles.page}>
                        <View style={pdfStyles.section}>
                          <Text style={pdfStyles.text}>Thank you for your visit</Text>
                        </View>
                        <View style={pdfStyles.view}>
                          <Image allowDangerousPaths src={pdfData.dataUrl} style={pdfStyles.qrImage} />
                        </View>
                        <View style={pdfStyles.view}>
                          <Text style={pdfStyles.text}>Amount: {pdfData.amount}</Text>
                        </View>
                      </Page>
                    </Document>
                  }
                  fileName="qr.pdf"
                >
                  Download
                </PDFDownloadLink>
              </Box>
            </Modal>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box css={style}>
                <form className="userUpdateForm">
                  <div className="userUpdateLeft">
                    <div className="userUpdateItem">
                      <h2>Create code</h2>
                      <br />
                      <label>Enter amount of revenue from customer - amount will be calculated by your coeficient specified in loyalty token settings.</label>
                      <br />
                      <input
                        type="number"
                        placeholder="Amount of EUR"
                        name="eurAmount"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="userUpdateRight">
                    {!isFetching ?
                      <button className="updateCampaignButton" onClick={handleCreate}>Create code</button>
                      :
                      <button className="updateCampaignButton updateCampaignButtonSpinner" disabled>
                        <CircularProgress style={{ "width": "20px", "height": "20px" }} />
                      </button>
                    }
                  </div>
                </form>
                <br />
                {error && !successfulSubmit && <Alert severity="error">Unfortunately error occured</Alert>}
                {successfulSubmit && <Alert severity="success">Code is created</Alert>}
              </Box>
            </Modal>
            <br/>
            <DataGrid
              autoHeight
              rows={codes}
              disableSelectionOnClick
              columns={columns}
              pageSize={8}
              checkboxSelection
              getRowId={(r) => r._id}
            />

            <br />
            {!codes.length > 0 ? <Alert severity="info">No codes created yet. Start creating codes.</Alert> : null}
          </div>
          :
          <div className="loading_spinner">
            <CircularProgress />
          </div>
        }
      </div>
      <button onClick={handleMintModalOpen} className="updateCampaignButton">Mint to user email</button>
    </div>
  );
}
