import "./campaign.css";
import Chart from "../../components/chart/Chart"
import { productData } from "../../dummyData"
import { Publish } from "@material-ui/icons";
import { Switch } from "@material-ui/core";
import { Link, useLocation, useParams } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { SingleCampaignContext } from "../../context/singleCampaignContext/SingleCampaignContext";
import { getCampaignById, updateCampaign } from "../../context/singleCampaignContext/apiCalls";
import { CircularProgress } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

export default function Campaign() {

    const params = useParams();
    const { campaignData, isFetching, error, dispatch } = useContext(SingleCampaignContext);
    const [successfulSubmit, setSuccessfulSubmit] = useState(false);
    const [campaign, setCampaign] = useState({ campaignTitle: campaignData.title });
    const [campaignTitle, setCampaignTitle] = useState(null);

    useEffect(() => {
        getCampaignById(params.campaignId, dispatch)
    }, [dispatch])

    const handleChange = (e) => {
        const value = e.target.value;
        if (e.target.type == "checkbox") {
            setCampaign({ ...campaign, [e.target.name]: e.target.checked })
        }
        setCampaign({ ...campaign, [e.target.name]: value })
    }

    const handleUpdate = (e) => {
        e.preventDefault();

        updateCampaign(params.campaignId, {
            "tokensToAlloc": campaign.campaignTokensToAlloc,
            "expirationDate": campaign.campaignExpirationDate,
            "title": campaign.campaignTitle,
            "description": campaign.campaignDescription,
            "totalBudgetQty": campaign.campaignTotalBudgetQty,
            "unlimitedBudget": campaign.campaignUnlimitedBudget,
            "maxPerCustomerQty": campaign.campaignMaxPerCustomerQty,
            "maxPerCustomerUnlimited": campaign.campaignMaxPerCustomerUnlimited
        }, dispatch)
        if (!error) {
            setSuccessfulSubmit(true)
        } else {
            console.error('UPDATE API ERROR ', error)
            setSuccessfulSubmit(false)
        }
    }

    campaign.campaignUnlimitedBudget = campaignData.unlimitedBudget;

    return (
        <div className="product">
            {!isFetching ?
                <div>
                    <div className="productTitleContainer">
                        <h1 className="productTitle">{campaignData && campaignData.title}</h1>
                        <Link to="/create-campaign">
                            <button className="campaignAddButton">Start new campaign</button>
                        </Link>
                    </div>
                    <div className="productTop">
                        <div className="productTopLeft">
                            <Chart data={productData} dataKey="Transactions" title="Campaign Performance" />
                        </div>
                    </div>
                    <div className="productBottom">
                        <form className="productForm">
                            <div className="productFormLeft">
                                <div className="productFormLeftItem">
                                    <label>Campaign title</label><br />
                                    <input
                                        type="text"
                                        placeholder="Title"
                                        name="campaignTitle"
                                        value={!campaign.campaignTitle ? campaignData.title : campaign.campaignTitle}
                                        onChange={handleChange}
                                    />
                                </div>
                                <br />
                                <div className="productFormLeftItem">
                                    <label>Campaign description</label><br />
                                    <textarea
                                        type="text"
                                        placeholder="Description"
                                        name="campaignDescription"
                                        value={!campaign.campaignDescription ? campaignData.description : campaign.campaignDescription}
                                        onChange={handleChange}
                                    />
                                </div>
                                <br />
                                <div className="productFormLeftItem">
                                    <label>Campaign created</label><br />
                                    <input
                                        type="text"
                                        placeholder="Created at"
                                        name="campaignCreated"
                                        value={!campaign.campaignCreated ? campaignData.createdAt : campaign.campaignCreated}
                                        onChange={handleChange}
                                        disabled
                                    />
                                </div>
                                <br />
                                <div className="productFormLeftItem">
                                    <label>Campaign last update:</label><br />
                                    <input
                                        type="text"
                                        placeholder="Last update"
                                        name="campaignUpdated"
                                        value={!campaign.campaignUpdated ? campaignData.updatedAt : campaign.campaignUpdated}
                                        onChange={handleChange}
                                        disabled
                                    />
                                </div>
                                <br />
                                <div className="productFormLeftItem">
                                    <label>Tokens to allocate Qty:</label><br />
                                    <input
                                        type="number"
                                        placeholder="Tokens to allocate Qty"
                                        name="campaignTokensToAlloc"
                                        value={!campaign.campaignTokensToAlloc ? campaignData.tokensToAlloc : campaign.campaignTokensToAlloc}
                                        onChange={handleChange}
                                    />
                                </div>
                                <br />
                                <div className="productFormLeftItem">
                                    <label>Expiration date:</label><br />
                                    <input
                                        type="text"
                                        placeholder="Expiration date"
                                        name="campaignExpirationDate"
                                        value={!campaign.campaignExpirationDate ? campaignData.expirationDate : campaign.campaignExpirationDate}
                                        onChange={handleChange}
                                    />
                                </div>
                                <br />
                                {!campaignData.unlimitedBudget && 
                                    <div className="productFormLeftItem">
                                        <label>Total budget Qty:</label><br />
                                        <input
                                            type="number"
                                            placeholder="Total budget Qty"
                                            name="campaignTotalBudgetQty"
                                            value={!campaign.campaignTotalBudgetQty ? campaignData.totalBudgetQty : campaign.campaignTotalBudgetQty}
                                            onChange={handleChange}
                                        />
                                    </div>
                                }
                                <br />
                                {campaignData.maxPerCustomerUnlimited && 
                                    <div className="productFormLeftItem">
                                        <label>Max per customer Qty:</label><br />
                                        <input
                                            type="number"
                                            placeholder="Total budget Qty"
                                            name="campaignMaxPerCustomerQty"
                                            value={!campaign.campaignMaxPerCustomerQty ? campaignData.maxPerCustomerQty : campaign.campaignMaxPerCustomerQty}
                                            onChange={handleChange}
                                        />
                                    </div>
                                }
                                <br />
                                <div className="productFormLeftItem">
                                    <label>Total budget is unlimited:</label><br />
                                    <Switch
                                        label="Total budget is unlimited:"
                                        name="campaignUnlimitedBudget"
                                        checked={!campaign.campaignUnlimitedBudget ? campaignData.unlimitedBudget : campaign.campaignUnlimitedBudget}
                                        // value={!campaign.campaignUnlimitedBudget ? campaignData.unlimitedBudget : campaign.campaignUnlimitedBudget}
                                        onChange={handleChange}
                                    />
                                </div>
                                <br />
                                <div className="productFormLeftItem">
                                    <label>Max qty per customer is unlimited:</label><br />
                                    <Switch
                                        label="Max qty per customer is unlimited:"
                                        name="campaignMaxPerCustomerUnlimited"
                                        checked={!campaign.campaignMaxPerCustomerUnlimited ? campaignData.maxPerCustomerUnlimited : campaign.campaignMaxPerCustomerUnlimited}
                                        onChange={handleChange}
                                    />
                                </div>
                                <br />
                                {!isFetching ?
                                    <button className="updateCampaignButton" onClick={handleUpdate}>Update campaign</button>
                                    :
                                    <button className="updateCampaignButton updateCampaignButtonSpinner" disabled>
                                        <CircularProgress style={{ "width": "20px", "height": "20px" }} />
                                    </button>
                                }

                            </div>
                        </form>
                        {error && !successfulSubmit && <Alert severity="error">Unfortunately error occured</Alert>}
                        {successfulSubmit && <Alert severity="success">Campaign is updated</Alert>}
                    </div>
                    <div className="campaignDetailBack">
                        <Link to="/campaigns">
                            <button className="campaignDetailBackButton">{"<<< Back to list"}</button>
                        </Link>
                    </div>
                </div>
                :
                <div className="loading_spinner">
                    <CircularProgress />
                </div>
            }
        </div>
    );
}
