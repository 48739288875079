import "./featuredInfo.css";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import web3 from "web3";

export default function FeaturedInfo() {
  const [tokenData, setTokenData] = useState();
  const [tokenId, setTokenID] = useState(JSON.parse(localStorage.getItem("user")).tokenId);

  useEffect(() => {
    loadTokenData(tokenId)
  }, [])

  const loadTokenData = async (tokenId) => {
    try {
      const res = await axios.get("https://loayltyapi.herokuapp.com/api/token/detail/" + tokenId, {
        headers: {
          token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken
        },
      });
      setTokenData(res.data)
    } catch (err) {
      console.log(err);
    }
  }

  const converFromWei = (amount) => {
    return web3.utils.fromWei(String(amount))
  }

  return (
    <div className="featured">
      <div className="featuredItem">
        <span className="featuredTitle">Tokens Allocated</span>
        <div className="featuredMoneyContainer"> 
          <span className="featuredMoney">{tokenData ? tokenData && converFromWei(tokenData && tokenData.totalSupply) : '0'}</span>
          <span className="featuredMoneyRate">
            <ArrowUpward className="featuredIcon" />
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Tokens Redeemed</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">0</span>
          <span className="featuredMoneyRate">
            <ArrowDownward className="featuredIcon negative" />
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Tokens Expired</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">0</span>
          <span className="featuredMoneyRate">
            <ArrowUpward className="featuredIcon negative" />
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div>
      {/* <div className="featuredItem">
        <span className="featuredTitle">Tokens Allocated</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">2,415</span>
          <span className="featuredMoneyRate">
            +110 <ArrowUpward  className="featuredIcon"/>
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Tokens Redeemed</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">1,415</span>
          <span className="featuredMoneyRate">
            -144 <ArrowDownward className="featuredIcon negative"/>
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">Tokens Expired</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">225</span>
          <span className="featuredMoneyRate">
            +24 <ArrowUpward className="featuredIcon negative"/>
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div> */}
    </div>
  );
}
