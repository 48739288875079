import axios from "axios";

import {
  createCampaignFailure,
  createCampaignsStart,
  createCampaignsSuccess,
  deleteCampaignFailure,
  deleteCampaignStart,
  deleteCampaignSuccess,
  getCampaignsFailure,
  getCampaignsStart,
  getCampaignsSuccess
} from "./CampaignActions";

export const getAllCampaigns = async (dispatch) => {
  dispatch(getCampaignsStart());
  try {
    const res = await axios.get("https://loayltyapi.herokuapp.com/api/campaigns/all", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getCampaignsSuccess(res.data));
  } catch (err) {
    console.error("API ERROR: ", err)
    dispatch(getCampaignsFailure());
  }
};

export const createCampaign = async (campaign, dispatch) => {
  dispatch(createCampaignsStart());
  try {
    const res = await axios.post("https://loayltyapi.herokuapp.com/api/campaigns/create", campaign, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createCampaignsSuccess(res.data));
  } catch (err) {
    console.error("API ERROR: ", err)
    dispatch(createCampaignFailure());
  }
};

export const deleteCampaign = async (id, dispatch) => {
  dispatch(deleteCampaignStart());
  try {
    await axios.delete("https://loayltyapi.herokuapp.com/api/campaigns/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteCampaignSuccess(id));
  } catch (err) {
    console.error("API ERROR: ", err)
    dispatch(deleteCampaignFailure());
  }
};