const TokenReducer = (state, action) => {
    switch (action.type) {
        case "GET_TOKENS_START":
            return {
                tokens: [],
                tokenDetail: [],
                isFetching: true,
                error: false,
            };
        case "GET_TOKENS_SUCCESS":
            return {
                tokens: action.payload,
                tokenDetail: [],
                isFetching: false,
                error: false,
            };
        case "GET_TOKENS_FAILURE":
            return {
                tokens: [],
                tokenDetail: [],
                isFetching: false,
                error: true,
            };
        case "GET_BALANCE_START":
            return {
                tokens: [],
                tokenDetail: [],
                balance: 0,
                isFetching: true,
                error: false,
            };
        case "GET_BALANCE_SUCCESS":
            return {
                tokens: [],
                tokenDetail: [],
                balance: action.payload,
                isFetching: false,
                error: false,
            };
        case "GET_BALANCE_FAILURE":
            return {
                tokens: [],
                tokenDetail: [],
                balance: 0,
                isFetching: false,
                error: true,
            };
        case "GET_TOKENS_DETAIL_START":
            return {
                tokens: [],
                tokenDetail: [],
                isFetching: true,
                error: false,
            };
        case "GET_TOKENS_DETAIL_SUCCESS":
            return {
                tokens: [...state.tokens],
                tokenDetail: action.payload,
                isFetching: false,
                error: false,
            };
        case "GET_TOKENS_DETAIL_FAILURE":
            return {
                tokens: [],
                tokenDetail: [],
                isFetching: false,
                error: true,
            };
        case "CREATE_TOKEN_START":
            return {
                ...state,
                tokenDetail: [],
                isFetching: true,
                error: false,
            };
        case "CREATE_TOKEN_SUCCESS":
            return {
                tokens: [...state.tokens, action.payload],
                tokenDetail: [],
                isFetching: false,
                error: false,
            };
        case "CREATE_TOKEN_FAILURE":
            return {
                ...state,
                tokenDetail: [],
                isFetching: false,
                error: true,
            };
        case "UPDATE_TOKEN_START":
            return {
                ...state,
                tokenDetail: [],
                isFetching: true,
                error: false,
            };
        case "UPDATE_TOKEN_SUCCESS":
            return {
                tokens: [...state.tokens, action.payload],
                tokenDetail: [],
                isFetching: false,
                error: false,
            };
        case "UPDATE_TOKEN_FAILURE":
            return {
                ...state,
                tokenDetail: [],
                isFetching: false,
                error: true,
            };
        case "MINT_TOKENS_START":
            return {
                ...state,
                tokenDetail: [],
                isFetching: true,
                error: false,
            };
        case "MINT_TOKENS_SUCCESS":
            return {
                tokens: [...state.tokens, action.payload],
                tokenDetail: [],
                isFetching: false,
                error: false,
            };
        case "MINT_TOKENS_FAILURE":
            return {
                ...state,
                tokenDetail: [],
                isFetching: false,
                error: true,
            };
        case "BURN_TOKENS_START":
            return {
                ...state,
                tokenDetail: [],
                isFetching: true,
                error: false,
            };
        case "BURN_TOKENS_SUCCESS":
            return {
                tokens: [...state.tokens, action.payload],
                tokenDetail: [],
                isFetching: false,
                error: false,
            };
        case "BURN_TOKENS_FAILURE":
            return {
                ...state,
                tokenDetail: [],
                isFetching: false,
                error: true,
            };
        default:
            return { ...state };
    }
};

export default TokenReducer;
