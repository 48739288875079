import React, { useContext, useState } from "react";
import { login } from "../../context/authContext/apiCalls";
import { AuthContext } from "../../context/authContext/AuthContext";
import "./login.css";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { isFetching, error, dispatch } = useContext(AuthContext);

  const handleLogin = (e) => {
    e.preventDefault();
    try {
      login({ email, password }, dispatch);
    } catch (err) {
      console.log('err ', err)
    }
  };

  return (
    <div className="continer">
      <div className="login">
      <div className="logo_login">
        <img src="../img/agevolt_logo.png" />
      </div>
        <form className="loginForm">
          <input
            type="text"
            placeholder="email"
            className="loginInput"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="password"
            className="loginInput"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            className="updateCampaignButton"
            onClick={handleLogin}
            disabled={isFetching}
          >
            Login
          </button>
          {error && <span className="login-validation">Wrong credentials or server error.<br/>Please contact support.</span>}
        </form>
      </div>
    </div>

  );
}
