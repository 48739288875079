const CampaignReducer = (state, action) => {
    switch (action.type) {
        case "GET_CAMPAIGNS_START":
            return {
                campaigns: [],
                isFetching: true,
                error: false,
            };
        case "GET_CAMPAIGNS_SUCCESS":
            return {
                campaigns: action.payload,
                isFetching: false,
                error: false,
            };
        case "GET_CAMPAIGNS_FAILURE":
            return {
                campaigns: [],
                isFetching: false,
                error: true,
            };
        case "CREATE_CAMPAIGN_START":
            return {
                ...state,
                isFetching: true,
                error: false,
            };
        case "CREATE_CAMPAIGN_SUCCESS":
            return {
                campaigns: [...state.campaigns, action.payload],
                isFetching: false,
                error: false,
            };
        case "CREATE_CAMPAIGN_FAILURE":
            return {
                ...state,
                isFetching: false,
                error: true,
            };
        case "UPLOAD_CAMPAIGN_START":
            return {
                ...state,
                isFetching: true,
                error: false,
            };
        case "UPLOAD_CAMPAIGN_SUCCESS":
            return {
                campaigns: state.campaigns.map(
                    (campaign) => campaign._id === action.payload._id && action.payload
                ),
                isFetching: false,
                error: false,
            };
        case "UPLOAD_CAMPAIGN_FAILURE":
            return {
                ...state,
                isFetching: false,
                error: true,
            };
        case "DELETE_CAMPAIGN_START":
            return {
                ...state,
                isFetching: true,
                error: false,
            };
        case "DELETE_CAMPAIGN_SUCCESS":
            return {
                campaigns: state.campaigns.filter((campaign) => campaign._id !== action.payload),
                isFetching: false,
                error: false,
            };
        case "DELETE_CAMPAIGN_FAILURE":
            return {
                ...state,
                isFetching: false,
                error: true,
            };
        default:
            return { ...state };
    }
};

export default CampaignReducer;
