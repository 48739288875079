import CampaignReducer from "./CampaignReducer";
import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  campaigns: [],
  isFetching: false,
  error: false,
};

export const CampaignContext = createContext(INITIAL_STATE);

export const CampaignContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CampaignReducer, INITIAL_STATE);

  return (
    <CampaignContext.Provider
      value={{
        campaigns: state.campaigns,
        isFetching: state.isFetching,
        error: state.error,
        dispatch,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};
