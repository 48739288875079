import "./campaignList.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import Alert from '@material-ui/lab/Alert';
import { CircularProgress } from "@material-ui/core";
import { productRows } from "../../dummyData";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useContext, useEffect } from "react";
import { CampaignContext } from "../../context/campaignContext/CampaignContext";
import { getAllCampaigns, deleteCampaign } from "../../context/campaignContext/apiCalls";


export default function CampaignList() {
  const [data, setData] = useState(productRows);

  const { campaigns, isFetching, dispatch } = useContext(CampaignContext);

  useEffect(() => {
    getAllCampaigns(dispatch)
  }, [dispatch])

  const handleDelete = (id) => {
    deleteCampaign(id, dispatch);
  };

  const columns = [
    // { field: "_id", headerName: "ID", width: 100 },
    {
      field: "title",
      headerName: "Title",
      width: 300,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {/* <img className="productListImg" src={params.row.img} alt="" /> */}
            {params.row.title}
          </div>
        );
      },
    },
    { 
      field: "totalBudgetQty", 
      headerName: "Total budget", 
      width: 200,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.unlimitedBudget ? "Unlimited" : params.row.totalBudgetQty}
          </div>
        );
      },
    },
    {
      field: "maxPerCustomerQty",
      headerName: "Max per customer",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="productListItem">
            {params.row.maxPerCustomerUnlimited ? "Unlimited" : params.row.maxPerCustomerQty}
          </div>
        );
      },
    },
    {
      field: "expirationDate",
      headerName: "Expiration date",
      width: 220,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/campaign/" + params.row._id}>
              <button className="productListEdit">Edit campaing</button>
            </Link>
            <DeleteOutline
              className="productListDelete"
              onClick={() => handleDelete(params.row._id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="productList">
      <div className="userTitleContainer">
        <h1 className="userTitle">Campaigns</h1>
        <Link to="/create-campaign">
          <button className="campaignAddButton">Start new campaign</button>
        </Link>
      </div>
      <div>
        {!isFetching
          ?
          <div>
            <DataGrid
              autoHeight
              rows={campaigns}
              disableSelectionOnClick
              columns={columns}
              pageSize={8}
              checkboxSelection
              getRowId={(r) => r._id}
            />
            <br/>
            {!campaigns.length > 0 ? <Alert severity="info">No campaigns created yet. Start creating campaigns.</Alert> : null}
          </div>
          :
          <div className="loading_spinner">
            <CircularProgress />
          </div>
        }
      </div>
    </div>
  );
}
