export const getCampaignStart = () => ({
    type: "GET_CAMPAIGN_START",
});

export const getCampaignSuccess = (campaign) => ({
    type: "GET_CAMPAIGN_SUCCESS",
    payload: campaign,
});

export const getCampaignFailure = () => ({
    type: "GET_CAMPAIGN_FAILURE",
});

export const updateCampaignStart = () => ({
    type: "UPDATE_CAMPAIGN_START",
});

export const updateCampaignSuccess = (campaign) => ({
    type: "UPDATE_CAMPAIGN_SUCCESS",
    payload: campaign,
});

export const updateCampaignFailure = () => ({
    type: "UPDATE_CAMPAIGN_FAILURE",
});