import axios from "axios";

import {
  getCampaignFailure,
  getCampaignStart,
  getCampaignSuccess,
  updateCampaignFailure,
  updateCampaignStart,
  updateCampaignSuccess
} from "./SingleCampaignActions";

export const getCampaignById = async (id, dispatch) => {
  dispatch(getCampaignStart());
  try {
    const res = await axios.get("https://loayltyapi.herokuapp.com/api/campaigns/detail/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getCampaignSuccess(res.data));
  } catch (err) {
    dispatch(getCampaignFailure());
  }
};

export const updateCampaign = async (id, campaign, dispatch) => {
  dispatch(updateCampaignStart());
  try {
    const res = await axios.put("https://loayltyapi.herokuapp.com/api/campaigns/detail/" + id, campaign, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updateCampaignSuccess(res.data));
  } catch (err) {
    dispatch(updateCampaignFailure());
  }
};