import {
  Hotel,
  Description,
  AccountBalanceWallet,
  ExpandMore,
  AddShoppingCart
} from "@material-ui/icons";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@material-ui/core"
import Alert from '@material-ui/lab/Alert';
import { Link, useLocation, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { TokenContext } from "../../context/tokenContext/TokenContext";
import {
  getTokenDetails,
  updateTokenDetails,
  mintTokens
} from "../../context/tokenContext/apiCalls";
import { CircularProgress } from "@material-ui/core";
import web3 from "web3";
import "./tokenDetails.css";

export default function TokenDetails() {
  const [tokenId, setTokenID] = useState(JSON.parse(localStorage.getItem("user")).tokenId);
  const [expanded, setExpanded] = useState(false);
  const { tokens, tokenDetail, isFetching, error, dispatch } = useContext(TokenContext);
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);
  const [tokenDataLoading, setTokenLoading] = useState(false);
  const [tokenNewData, setTokenData] = useState({ tokenTitle: tokenDetail.name });

  useEffect(() => {
    loadTokenData()
  }, [dispatch])

  const loadTokenData = () => {
    getTokenDetails(tokenId, dispatch)
  }

  const handleExpander = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setTokenData({ ...tokenNewData, [e.target.name]: value })
  }

  const handleUpdate = (e) => {
    setTokenLoading(true)
    updateTokenDetails(tokenId, {
      "name": tokenNewData.tokenTitle,
      "symbol": tokenNewData.tokenSymbol,
      "description": tokenNewData.tokenDescription,
      "rate": tokenNewData.tokenRate
    }, dispatch)

    if (!error) {
      loadTokenData()
      setTokenLoading(false)
      setSuccessfulSubmit(true)
    } else {
      console.error('UPDATE API ERROR ', error)
      setSuccessfulSubmit(false)
    }
  }

  const converFromWei = (amount) => {
    return web3.utils.fromWei(String(amount))
  }

  return (
    <div className="tokenDetailsContainer">
      <div className="user">
        <div className="userTitleContainer">
          <h1 className="userTitle">Loyalty token</h1>
          
        </div>
        {!isFetching || !tokenDataLoading && tokenDetail && tokenDetail._id ?
          <div className="tokenContainer">
            <h3>Edit token information or pricing coeficient based on your expenses</h3>
            <div className="tokenShow">
              <div className="userShowBottom">
                <span className="userShowTitle">Token Details</span>
                <div className="userShowInfo">
                  <Hotel className="userShowIcon" />
                  <span className="userShowInfoTitle"><span>Name: </span>{tokenDetail && tokenDetail.name} - {tokenDetail && tokenDetail.symbol}</span>
                </div>
                <div className="userShowInfo">
                  <AccountBalanceWallet className="userShowIcon" />
                  <span className="userShowInfoTitle"><span>Current allocated supply:</span> {tokenDetail && converFromWei(tokenDetail.totalSupply ? tokenDetail.totalSupply : 0) + " " + tokenDetail.symbol}</span>
                </div>
                <div className="userShowInfo">
                  <AddShoppingCart className="userShowIcon" />
                  <span className="userShowInfoTitle"><span>Pricing coeficient:</span> {tokenDetail && tokenDetail.rate}</span>
                </div>
                <div className="userShowInfo">
                  <Description className="userShowIcon" />
                  <span className="userShowInfoTitle"><span>Description: </span>{tokenDetail && tokenDetail.description}</span>
                </div>
              </div>
            </div>
            <br/>
            <div className="tokenDetailsFunc">
              {/* <span className="userShowTitle">Token Settings</span> */}
              <div className="tokenAccordion">
                <Accordion className="tokenAccordionExpander" expanded={expanded === 'panel1'} onChange={handleExpander('panel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <b>Update token details</b>
                  </AccordionSummary>
                  <AccordionDetails className="tokenUpdateAccordion">
                    <form className="tokenUpdateForm">
                      <input
                        type="text"
                        placeholder="New token title"
                        name="tokenTitle"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        placeholder="New token symbol"
                        name="tokenSymbol"
                        onChange={handleChange}
                      />
                      <br />
                      <input
                        type="text"
                        placeholder="Coeficiet"
                        name="tokenRate"
                        onChange={handleChange}
                      />
                      <br />
                      <textarea
                        type="text"
                        placeholder="New token description..."
                        name="tokenDescription"
                        onChange={handleChange}
                      />
                      <br />
                      {!isFetching ?
                        <button className="updateCampaignButton" onClick={handleUpdate}>Update token</button>
                        :
                        <button className="updateCampaignButton updateCampaignButtonSpinner" disabled>
                          <CircularProgress style={{ "width": "20px", "height": "20px" }} />
                        </button>
                      }
                    </form>
                    <br />
                    {error && !successfulSubmit && <Alert severity="error">Unfortunately error occured</Alert>}
                    {successfulSubmit && <Alert severity="success">Token is updated</Alert>}
                  </AccordionDetails>
                </Accordion>
                {/* <Accordion expanded={expanded === 'panel2'} onChange={handleExpander('panel2')}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <b>{'Create & Print QR Codes'}</b>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
              </div>
            </div>
          </div>
          :
          <div className="loading_spinner">
            <CircularProgress />
          </div>
        }
      </div>
      <div className="footerUserDetail">
        {/* <Link to="/users">
          <button className="userDetailBackButton">Back</button>
        </Link> */}
      </div>
    </div>
  );
}
