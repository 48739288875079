import Sidebar from "./components/sidebar/Sidebar";
import Topbar from "./components/topbar/Topbar";
import "./App.css";
import Home from "./pages/home/Home";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import UserList from "./pages/userList/UserList";
import User from "./pages/user/User";
import NewUser from "./pages/newUser/NewUser";
import Login from "./pages/login/Login";
import { AuthContext } from "./context/authContext/AuthContext";
import { useContext } from "react";
import CampaignList from "./pages/campaignList/CampaignList";
import Campaign from "./pages/campaign/Campaign";
import NewCampaign from "./pages/newCampaign/NewCampaign";
import TokenDetails from "./pages/tokenDetails/TokenDetails";
import Codes from "./pages/codeList/Codes";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";

function App() {
  const { user } = useContext(AuthContext);
  return (
    <Router>
      <Switch>
        <Route path="/login">{user ? <Redirect to="/" /> : <Login />}</Route>
        {user ? (
          <>
            <Topbar />
            <div className="container">
              <Sidebar />
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/users">
                <UserList />
              </Route>
              <Route path="/user/:userId">
                <User />
              </Route>
              <Route path="/new-user-access">
                <NewUser />
              </Route>
              <Route path="/campaigns">
                <CampaignList />
              </Route>
              <Route path="/campaign/:campaignId">
                <Campaign />
              </Route>
              <Route path="/create-campaign">
                <NewCampaign />
              </Route>
              <Route path="/loyalty-token">
                <TokenDetails />
              </Route>
              <Route path="/codes">
                <Codes />
              </Route>
            </div>
          </>
        ) :
          <Login />
        }
      </Switch>
    </Router>

  );
}

export default App;
