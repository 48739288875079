const CodesReducer = (state, action) => {
    switch (action.type) {
        case "GET_ALL_CODES_START":
            return {
                codes: [],
                isFetching: true,
                error: false,
            };
        case "GET_ALL_CODES_SUCCESS":
            return {
                codes: action.payload,
                isFetching: false,
                error: false,
            };
        case "GET_ALL_CODES_FAILURE":
            return {
                codes: [],
                isFetching: false,
                error: true,
            };
        case "CREATE_CODES_START":
            return {
                ...state,
                isFetching: true,
                error: false,
            };
        case "CREATE_CODES_SUCCESS":
            return {
                codes: [...state.codes, action.payload],
                isFetching: false,
                error: false,
            };
        case "CREATE_CODES_FAILURE":
            return {
                ...state,
                isFetching: false,
                error: true,
            };
        case "DELETE_CODES_START":
            return {
                ...state,
                isFetching: true,
                error: false,
            };
        case "DELETE_CODES_SUCCESS":
            return {
                codes: state.codes.filter((code) => code._id !== action.payload),
                isFetching: false,
                error: false,
            };
        case "DELETE_CODES_FAILURE":
            return {
                ...state,
                isFetching: false,
                error: true,
            };
        default:
            return { ...state };
    }
};

export default CodesReducer;
