import axios from "axios";

import {
  getTokensStart,
  getTokensSuccess,
  getTokensFailure,
  getTokensDetailStart,
  getTokensDetailSuccess,
  getTokensDetailFailure,
  updateTokensStart,
  updateTokensSuccess,
  updateTokenFailure,
  mintTokensStart,
  mintTokensSuccess,
  mintTokenFailure,
  getBalanceStart,
  getBalanceSuccess,
  getBalanceFailure
} from "./TokenActions";



export const getAllTokens = async (dispatch) => {
  dispatch(getTokensStart());
  try {
    const res = await axios.get("https://loayltyapi.herokuapp.com/api/token/all", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getTokensSuccess(res.data));
  } catch (err) {
    console.error("API ERROR: ", err)
    dispatch(getTokensFailure());
  }
};

export const getTokenDetails = async (id, dispatch) => {
  dispatch(getTokensDetailStart());
  try {
    const res = await axios.get("https://loayltyapi.herokuapp.com/api/token/detail/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getTokensDetailSuccess(res.data));
  } catch (err) {
    console.error("API ERROR: ", err)
    dispatch(getTokensDetailFailure());
  }
};

export const updateTokenDetails = async (id, tokenData, dispatch) => {
  dispatch(updateTokensStart());
  try {
    const res = await axios.put("https://loayltyapi.herokuapp.com/api/token/detail/" + id, tokenData, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(updateTokensSuccess(res.data));
  } catch (err) {
    dispatch(updateTokenFailure());
  }
};

export const mintTokens = async (id, tokenData, dispatch) => {
  dispatch(mintTokensStart());
  try {
    const res = await axios.post("https://loayltyapi.herokuapp.com/api/token/mint/" + id, tokenData, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(mintTokensSuccess(res.data));
  } catch (err) {
    console.error("API ERROR: ", err)
    dispatch(mintTokenFailure());
  }
};

export const getBalance = async (tokenaddress, walletaddress, dispatch) => {
  dispatch(getBalanceStart());
  try {
    const res = await axios.get("https://loayltyapi.herokuapp.com/api/token/balance/" + tokenaddress + "/" + walletaddress, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getBalanceSuccess(res.data));
  } catch (err) {
    console.error("API ERROR: ", err)
    dispatch(getBalanceFailure());
  }
};