import axios from "axios";

import {
  getAllCodesStart,
  getAllCodesSuccess,
  getAllCodesFailure,
  createCodesStart,
  createCodesSuccess,
  createCodesFailure,
  deleteCodesStart,
  deleteCodesSuccess,
  deleteCodesFailure
} from "./CodesActions";

export const getAllCodes = async (dispatch) => {
  dispatch(getAllCodesStart());
  try {
    const res = await axios.get("https://loayltyapi.herokuapp.com/api/codes/all", {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(getAllCodesSuccess(res.data));
  } catch (err) {
    console.error("API ERROR: ", err)
    dispatch(getAllCodesFailure());
  }
};

export const createCode = async (id, code, dispatch) => {
  dispatch(createCodesStart());
  try {
    const res = await axios.post("https://loayltyapi.herokuapp.com/api/codes/print-qr/" + id, code, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(createCodesSuccess(res.data));
  } catch (err) {
    console.error("API ERROR: ", err)
    dispatch(createCodesFailure());
  }
};

export const deleteCode = async (id, dispatch) => {
  dispatch(deleteCodesStart());
  try {
    await axios.delete("https://loayltyapi.herokuapp.com/api/codes/print-qr/" + id, {
      headers: {
        token: "Bearer " + JSON.parse(localStorage.getItem("user")).accessToken,
      },
    });
    dispatch(deleteCodesSuccess(id));
  } catch (err) {
    console.error("API ERROR: ", err)
    dispatch(deleteCodesFailure());
  }
};