import "./newUser.css";
import { CircularProgress } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { Link, useHistory } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../context/userContext/UserContext";
import { createUser } from "../../context/userContext/apiCalls";
import axios from "axios";
import web3 from "web3";

export default function NewUser() {
  const history = useHistory();
  const { isFetching, error, dispatch } = useContext(UserContext);
  const [successfulSubmit, setSuccessfulSubmit] = useState(false);
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [userAdminPermission, setAdminPermission] = useState(null);
  const [preregResponse, setPreregResponse] = useState();

  const handleChange = (e) => {
    const value = e.target.value;
    setUser({ ...user, [e.target.name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    
    createUser({
      "username": user.userName,
      "email": user.userName,
      "password": user.userPassword,
      "isAdmin": !user.userAdminPermission ? false : true,
      "owner": JSON.parse(localStorage.getItem("user"))._id
    }, dispatch);
    // history.push("/users");
    if (!error) {
      setSuccessfulSubmit(true)
    } else {
      setSuccessfulSubmit(false)
    }
  };

  return (
    <div className="newUser">
      <h1 className="newUserTitle">New User</h1>
      <form className="newUserForm">
        <div className="newUserItem">
          <label>Username</label>
          <input
            type="text"
            placeholder="New Username"
            name="userName"
            onChange={handleChange}
          />
        </div>
        <div className="newUserItem">
          <label>Email</label>
          <input
            type="email"
            placeholder="new@email.com"
            name="userEmail"
            onChange={handleChange}
          />
        </div>
        <div className="newUserItem">
          <label>Password</label>
          <input
            type="password"
            placeholder="password"
            name="userPassword"
            onChange={handleChange}
          />
        </div>
        <div className="newUserItem">
          <label>Admin permissions</label>
          <select
            className="newUserSelect"
            name="active" id="active"
            name="userAdminPermission"
            onChange={handleChange}
          >
            <option value={false}>---</option>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </div>

        <Link to="/users">
          <button className="newUserBackButton">Back</button>
        </Link>

        {!isFetching ?
          <button className="newUserButton" onClick={handleSubmit}>Create</button>
          :
          <button className="newUserButton newUserButtonSpinner" disabled>
            <CircularProgress style={{ "width": "20px", "height": "20px" }} />
          </button>
        }
      </form>
      {error && !successfulSubmit && <Alert severity="error">Unfortunately error occured</Alert>}
      {successfulSubmit && <Alert severity="success">User is created</Alert>}
    </div>
  );
}
